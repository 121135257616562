// provides web-socket handlers for net (node/api/proxy) access

import {
  getApiHostForStandaloneRun,
  getAppIdForStandaloneRun,
  isRunningInStandaloneMode,
} from "../../utils/standalone-utils";
import { uuid, json, parse } from "../../utils/utils";

const getWSUrl = (wsPath) => {
  if (isRunningInStandaloneMode()) {
    const apiHost = getApiHostForStandaloneRun();
    if (apiHost.startsWith("https://")) {
      return "wss://" + apiHost.replace("https://", "") + "/" + wsPath;
    }
    else {
      return "ws://" + apiHost.replace("http://", "") + "/" + wsPath;
    }
  } else {
    const { protocol, hostname, port, pathname } = window.location;

    const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
    const wsHost = hostname;
    const wsPort = port ? ":" + port : "";

    return wsProtocol + wsHost + wsPort + pathname + wsPath;
  }
};

export function wsConnect(wsPath = "", on_open, on_msg, retry = 10000) {
  const ws = new WebSocket(getWSUrl(wsPath));
  ws.onopen = (event) => {
    on_open(ws, event);
  };

  ws.onmessage = on_msg;

  ws.onerror = (event) => {
    console.error("ws error:", event);
  };

  ws.onclose = (event) => {
    console.log("ws connection closed:", event);
    setTimeout(() => {
      wsConnect(wsPath, on_open, on_msg, retry);
    }, retry);
  };
}

export async function wsProxyApi(type) {
  const ctx = {
    send: (msg) => {
      ctx.ws.send(json(msg));
    },
    once: {},
    subs: {},
    ready: [],
  };
  const api = {
    publish: (topic, msg) => {
      ctx.send({ fn: "publish", topic, msg });
    },
    subscribe: (topic, handler, timeout) => {
      if (isRunningInStandaloneMode()) {
        topic = topic.replace("$", getAppIdForStandaloneRun());
      } else {
        topic = topic.replace("$", ctx.app_id || "unknown");
      }

      ctx.subs[topic] = handler;
      ctx.send({ fn: "subscribe", topic, timeout });
    },
    call: (topic, msg, handler) => {
      if (isRunningInStandaloneMode()) {
        topic = topic.replace("$", getAppIdForStandaloneRun());
      }

      const mid = uuid();
      ctx.once[mid] = handler;
      if (type === "proxy") {
        ctx.send({ fn: "call", topic, msg, mid });
      } else if (type === "admin") {
        ctx.send({ fn: "call", cmd: topic, args: msg, cid: mid });
      }
    },
    send: (topic, msg) => {
      ctx.send({ fn: "send", topic, msg });
    },
    pcall: (topic, msg) => {
      return new Promise((resolve, reject) => {
        api.call(topic, msg, (msg, error, topic) => {
          if (error) {
            reject(error);
          } else {
            resolve(msg);
          }
        });
      });
    },
    on_ready: (fn) => {
      if (ctx.app_id) {
        fn(ctx.app_id);
      } else {
        ctx.ready.push(fn);
      }
    },
  };
  return new Promise((resolve) => {
    wsConnect(
      type === "proxy" ? "proxy.api" : "admin.api",
      (ws) => {
        ctx.ws = ws;
        resolve(api);
      },
      (event) => {
        const ws_msg = parse(event.data);
        const { pub, msg, args, app_id } = ws_msg;
        if (app_id) {
          ctx.app_id = app_id;
          // set window key to access it anywhere.
          // TODO to improve state management in app.
          window["procurement_app_id"] = app_id;
          ctx.ready.forEach((fn) => fn(app_id));
        } else if (pub) {
          const handler = ctx.subs[pub];
          if (handler) {
            handler(msg, pub);
          } else {
            console.log({ missing_sub: pub, subs: ctx.subs });
          }
        } else {
          const { mid, cid, topic, error } = ws_msg;
          const handler = ctx.once[mid] || ctx.once[cid];
          delete ctx.once[mid];
          delete ctx.once[cid];
          if (!handler) {
            console.log({ missing_once: mid, ws_msg });
          } else if (error) {
            handler(undefined, error, topic);
          } else {
            handler(msg ?? args, undefined, topic);
          }
        }
      }
    );
  });
}
