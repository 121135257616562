import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Spin,
  Table,
  Modal,
  Form,
  message,
  Row,
  Col,
  Switch,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { generatePassword } from "utils/helper";
import { createUser, toggleAdmin } from "api/proxy/auth-api";
import { useAppContext } from "context/appContext";
import { deleteUser, getUserList, resetUser } from "api/proxy/auth-api";
import { ContentCard } from "components/cards";
import { Input, Button } from "components/form";

import Icon from "components/icon";
import Section from "components/section";

import Styles from "./styles/user.module.scss";

const { confirm } = Modal;

const Users = () => {
  const { meData } = useAppContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "ORG ADMIN",
        key: "is_admin",
        width: "20%",
        align: "center",
        render: (_, record) => (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={handleToggleAdmin.bind(null, record)}
            checked={meData?.user && record?.is_admin}
            disabled={meData?.user && record?.name === meData?.user}
          />
        ),
      },
      {
        title: "Action",
        key: "action",
        width: "20%",
        align: "center",
        render: (_, record) => (
          <Space size="middle" className={Styles["button-wrapper"]}>
            <Button
              type="link"
              icon={<RestartAltOutlinedIcon />}
              onClick={() => handleReset(record)}
            >
              Reset
            </Button>
            {meData?.user && record?.name !== meData?.user && (
              <Button
                type="link"
                icon={<DeleteOutlineOutlinedIcon />}
                danger
                onClick={() => handleDelete(record)}
              >
                Delete
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [meData]
  );

  useEffect(() => {
    setLoading(true);
    if (meData?.user) {
      getUserList({}, (msg) => {
        if (msg) {
          setAllUsers(msg);
          setUserList(msg);
          setLoading(false);
        }
      });
    }
  }, [meData?.org_admin, meData?.user]);

  const handleReset = (record) => {
    setIsReset(true);
    setCurrentRecord(record);
  };

  const handleDelete = (record) => {
    confirm({
      title: `Are you sure you want to delete "${record.name}"?`,
      icon: <ExclamationCircleFilled />,
      centered: true,
      onOk() {
        deleteUser({ user: record.name }, () => {
          setUserList((prev) =>
            prev.filter((item) => item.name !== record.name)
          );
        });
      },
      onCancel() {},
    });
  };

  const handleToggleAdmin = (record) => {
    confirm({
      title: `Confirm change to make "${record.name}" ${
        record.is_admin ? "a" : "an"
      } ${record.is_admin ? "non-" : ""}org admin user
      ?`,
      icon: <ExclamationCircleFilled />,
      centered: true,
      onOk() {
        toggleAdmin({ user: record.name, admin: !record.is_admin }, () => {
          getUserList({}, (msg) => {
            if (msg) {
              setAllUsers(msg);
              setUserList(msg);
              setLoading(false);
            }
          });
        });
      },
      onCancel() {},
    });
  };

  const handleResetSubmit = (data) => {
    resetUser({ user: currentRecord?.name, ...data }, (msg) => {
      message.success({
        content: "Password successfully changed.",
      });
      setIsReset(false);
      setCurrentRecord(null);
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const searchedUsers = allUsers.filter((user) =>
      user.name.toLowerCase().includes(value)
    );
    if (value && searchedUsers.length !== 0) {
      setUserList(searchedUsers);
    } else if (value && searchedUsers.length === 0) {
      setUserList([]);
    } else {
      setUserList(allUsers);
    }
  };

  const handleCreateUserSubmit = (data) => {
    setIsSubmitLoading(true);
    createUser(
      {
        user: data.email,
        pass: generatePassword(),
      },
      (msg) => {
        setAllUsers((prev) => [{ name: data.email, is_admin: false }, ...prev]);
        setUserList((prev) => [{ name: data.email, is_admin: false }, ...prev]);
        form.resetFields();
      }
    );
    setIsSubmitLoading(false);
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles["apps-area"]}>
        <Section>
          <Row gutter={30}>
            <Col sm={12}>
              <ContentCard title="Users management">
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <Space>
                      <Input
                        prefix={<SearchOutlinedIcon />}
                        placeholder="Search"
                        className={Styles["search-input"]}
                        onChange={handleSearch}
                      />
                    </Space>
                    <Table
                      columns={columns}
                      dataSource={userList}
                      pagination={{
                        pageSize: 4,
                      }}
                    />
                  </>
                )}
              </ContentCard>
            </Col>
            <Col sm={12}>
              <ContentCard title="Create a new user" transparent>
                <Form form={form} onFinish={handleCreateUserSubmit}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter valid email",
                      },
                    ]}
                  >
                    <Input placeholder="E-mail - ID" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className={Styles["submit-button"]}
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitLoading}
                    >
                      Create new user
                    </Button>
                  </Form.Item>
                </Form>
              </ContentCard>
            </Col>
          </Row>
        </Section>
      </div>

      <Modal
        title="Reset"
        onCancel={() => {
          setIsReset(false);
          setCurrentRecord(null);
        }}
        footer={[]}
        open={isReset}
        centered
        destroyOnClose
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleResetSubmit}
        >
          <Form.Item
            name="pass"
            label={`Set a new password for "${currentRecord?.name}"`}
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input placeholder="Enter new password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Users;
