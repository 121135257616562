import { Card as AntdCard, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import NoImage from "assets/images/img/no-image.jpg";

import Styles from "./styles/card.module.scss";

const { Meta } = AntdCard;

const Card = ({
  children,
  title,
  className,
  heading,
  like,
  cover,
  coverHeight,
  status,
  onDelete,
  item,
  isAdmin,
  appsContext,
  ...props
}) => {
  const navigate = useNavigate();
  
  const handleCardClick = (id) => {
    navigate(`/app/${id}`);
  }

  return (
    <AntdCard
      title={title}
      className={`${Styles.card} ${className ? className : ""} ${
        !item.alive ? Styles.disabled : ""
      }`}
      cover={
        <img
          src={cover ? cover : NoImage}
          height={coverHeight || "auto"}
          alt=""
        />
      }
      {...props}
      onClick={item.alive ? () => handleCardClick(item.uid) : () => {}}
    >
      {!item.alive && <Tag className={Styles.tag}>Unavailable</Tag>}
      <div className={Styles.head}>
        {(heading || like) && (
          <div className={Styles.heading}>
            <p>{heading}</p>
            {like !== undefined && <div className={Styles.like}>{like}</div>}
          </div>
        )}
        {/* <Space className={Styles.space}>
          <Button icon={<DnsOutlinedIcon />}>Duplicate</Button>
          <Button
            type="primary"
            icon={<RocketLaunchOutlinedIcon />}
            onClick={() => handleLaunchClick(item.uid, appsContext)}
            disabled={!item.alive}
          >
            Open
          </Button>
        </Space> */}
      </div>
      {children}
    </AntdCard>
  );
};

Card.Meta = Meta;

export default Card;
