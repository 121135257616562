import { useEffect } from "react";
import { message } from "antd";
import Styles from "./styles/loginSuccess.module.scss";
import { getUserSSOSession, setupAuthAPISession } from "api/proxy/auth-api";
import { useSocketContext } from "api/core/context/websocket-api-context";
import { useAppContext } from "context/appContext";
import { useNavigate } from "react-router-dom";
import { LS } from "utils/utils";

const LoginSuccessLanding = (props) => {
  const navigate = useNavigate();

  const codeParam = props.codeParam;

  const { api, adminApi } = useSocketContext();
  const { setIsAuthenticated, setMeData } = useAppContext();

  useEffect(() => {
    if (!codeParam) {
      message.open({
        type: "error",
        content: "Invalid SSO login request",
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }

    if (api || adminApi) {
      setupAuthAPISession(api, setMeData);

      getUserSSOSession(
        {
          code: codeParam,
        },
        (data) => {
          if (data) {
            if (data && data.sid) {
              setMeData(data);
              if (data.sid) {
                LS.set("session", data.sid);
                setIsAuthenticated(true);
                window.location = `/`;
              }
            } else {
              message.open({
                type: "error",
                content: "Invalid SSO login request. Failed to get session",
              });
              setIsAuthenticated(false);
              navigate("/login");
            }
          }
        },
      );
    }
  }, [codeParam, api, adminApi, setMeData, navigate, setIsAuthenticated]);

  return (
    <div className={Styles["login-success-wrapper"]}>
      {!codeParam
        ? "Invalid SSO login request"
        : "Please wait. Redirecting to home page..."}
    </div>
  );
};

export default LoginSuccessLanding;
