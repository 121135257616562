import Header from "components/header";

import Styles from "./styles/layout.module.scss";

const Layout = ({ children }) => {
  return (
    <div className={Styles.layout}>
      <Header />
      <div className={Styles["content-area"]}>{children}</div>
    </div>
  );
};

export default Layout;
