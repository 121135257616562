import { useCallback, useEffect, useMemo, useState } from "react";
import { Divider, Select, Space, Table, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { deleteApp, getApps, updateApp } from "api/proxy/admin-api";
import { getUserList } from "api/proxy/auth-api";
import { useAppContext } from "context/appContext";

import { Card } from "components/cards";
import { Button } from "components/form";
import Icon from "components/icon";

import Styles from "./styles/settings.module.scss";

const { confirm } = Modal;

const Settings = () => {
  const { meData } = useAppContext();
  const [open, setOpen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSaveLoding, setIsSaveLoading] = useState(false);
  const [appList, setAppList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);

  const fetchApps = useCallback(() => {
    setLoading(true);
    if (meData?.user) {
      getApps(
        { admin: meData?.org_admin || false, user: meData?.user },
        (apps) => {
          if (apps) {
            setAppList(apps);
            setOpen(new Array(apps.length).fill(false));
          }
        }
      );
      setLoading(false);
    }
  }, [meData?.org_admin, meData?.user]);

  const handleSaveClick = useCallback(
    async (record, index) => {
      setIsSaveLoading(true);
      await updateApp(
        {
          rec: {
            name: record.name,
            users: record.users,
            admins: selectedAdmins,
          },
          uid: record.uid,
        },
        () => {
          fetchApps();
          const dummyArray = [...open];
          dummyArray[index] = false;
          setOpen(dummyArray);
        }
      );
      setIsSaveLoading(false);
    },
    [fetchApps, selectedAdmins, open]
  );

  const handleDropdownVisibleChange = useCallback(
    (flag, index) => {
      const dummyArray = [...open];
      dummyArray[index] = flag;
      setOpen(dummyArray);
    },
    [open]
  );

  const handleDelete = useCallback((record) => {
    confirm({
      title: `Do you want to delete these app?`,
      icon: <ExclamationCircleFilled />,
      centered: true,
      onOk() {
        deleteApp({ uid: record.uid, name: record.name }, () => {
          setAppList((prev) => {
            return prev.filter((item) => item.uid !== record.uid);
          });
        });
      },
      onCancel() {},
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Applications",
        dataIndex: "name",
        key: "name",
        width: "40%",
        render: (name, record) => (
          <div className={Styles["app-name"]}>{name}</div>
        ),
      },
      {
        title: "App admins",
        dataIndex: "app_admins",
        key: "app_admins",
        width: "60%",
        render: (_, record, index) => (
          <Select
            placeholder="Select app admins"
            open={open[index]}
            style={{ width: 400 }}
            mode="multiple"
            onChange={(value) => setSelectedAdmins(value)}
            defaultValue={record.admins}
            onDropdownVisibleChange={(flag) =>
              handleDropdownVisibleChange(flag, index)
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button
                    type="primary"
                    onClick={() => handleSaveClick(record, index)}
                    loading={isSaveLoding}
                  >
                    Save
                  </Button>
                </Space>
              </>
            )}
            options={allUsers.map((user) => {
              return {
                label: user.name,
                value: user.name,
              };
            })}
          />
        ),
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        render: (_, record) => (
          <Space size="middle" align="center">
            <Button
              type="primary"
              icon={<SignalCellularAltOutlinedIcon />}
              className={Styles["reset-btn"]}
              disabled
              // onClick={() => handleReset(record)}
            >
              Analytics
            </Button>
            {meData?.user && record?.name !== meData?.user && (
              <Button
                type="primary"
                icon={<DeleteOutlineOutlinedIcon />}
                className={Styles["reset-btn"]}
                danger
                onClick={() => handleDelete(record)}
              >
                Delete
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [
      meData,
      allUsers,
      handleSaveClick,
      isSaveLoding,
      open,
      handleDropdownVisibleChange,
      handleDelete,
    ]
  );

  useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  useEffect(() => {
    if (meData?.user) {
      getUserList({}, (msg) => {
        if (msg) {
          setAllUsers(msg);
        }
      });
    }
  }, [meData?.org_admin, meData?.user]);

  return (
    <Table
      rowKey={(record) => record.uid}
      columns={columns}
      dataSource={appList}
      loading={loading}
      pagination={{
        pageSize: 6,
      }}
    />
  );
};

export default Settings;
