import { getAppIdForStandaloneRun, isRunningInStandaloneMode } from "./standalone-utils";

export function uid() {
    return `${Date.now().toString(36).padStart(8, 0)}${(Math.round(Math.random() * 0xffffffffff)).toString(36).padStart(8, 0)}`;
}

export function uuid() {
    return 'xxxxx-xxxxx-xxxxx-xxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function json(o) {
    return JSON.stringify(o);
}

export function parse(s) {
    return JSON.parse(s)
}

export const LS = {
    get(key) { return localStorage.getItem(key) },
    set(key, val) { return localStorage.setItem(key, val) },
    delete(key) { return localStorage.removeItem(key) },
};

export function getAppId() {
    if (isRunningInStandaloneMode()) {
        return getAppIdForStandaloneRun();
    } else {
        return window['procurement_app_id'];
    }
}

export function getAppRootPath() {

    if (isRunningInStandaloneMode()) {
        console.log('Running in standalone mode');
        return '/';
    } else if (window['procurement_app_id']) {
        return `/app/${window['procurement_app_id']}`
    } else {
        const currentPathname = window.location.pathname;
        const pathSegments = currentPathname.split('/').filter(segment => segment !== '');
        if (pathSegments.length < 2) {
            return '/';
        }
        // Extract until the second path parameter
        return `/${pathSegments[0]}/${pathSegments[1]}`;
    }
}