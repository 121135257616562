import { Button } from "antd";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

import Icon from "components/icon";
import DocIcon from "assets/images/svg/doc-icon.svg";
import Logo from "assets/images/svg/logo.svg";

import Styles from "./styles/docsInfo.module.scss";

const DocsInfo = () => {
  return (
    <div className={Styles["info-wrapper"]}>
      <div className={Styles.icon}>
        <img src={DocIcon} alt="" />
      </div>
      <div className={Styles.text}>
        <div>
          <p>Welcome to</p>
          <img src={Logo} alt="Morfius" />
        </div>
        <p>
          Let's walk together and take a quick tour to
          <br /> start build something incredible!
        </p>
      </div>
      <div className={Styles.action}>
        <Button
          onClick={() => window.open("https://www.morfius.ai", "_blank")}
          icon={<StarBorderOutlinedIcon />}
          className={Styles.btn}
          type="primary"
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

export default DocsInfo;
