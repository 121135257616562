import { Input as AntdInput } from "antd";
import { forwardRef } from "react";

const { Password, TextArea } = AntdInput;

const Input = forwardRef(
  (
    { type, placeholder, value, onChange, className, height, ...props },
    ref
  ) => {
    return (
      <>
        {type === "password" ? (
          <Password
            ref={ref || null}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={className}
            style={height !== "default" ? { height: 50 } : {}}
            {...props}
          />
        ) : type === "textarea" ? (
          <TextArea
            ref={ref || null}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={className}
            {...props}
          />
        ) : (
          <AntdInput
            ref={ref || null}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={className}
            style={height !== "default" ? { height: 50 } : {}}
            {...props}
          />
        )}
      </>
    );
  }
);

export default Input;
