import * as React from "react"
const DesktopIcon = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    className={className}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.333 2.5H2.5v5.833h5.833V2.5ZM17.5 2.5h-5.833v5.833H17.5V2.5ZM17.5 11.667h-5.833V17.5H17.5v-5.833ZM8.333 11.667H2.5V17.5h5.833v-5.833Z"
    />
  </svg>
);
export default DesktopIcon
