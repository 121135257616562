import React, { useEffect, useRef, useState } from "react";
import WebSocketApiContext from "./websocket-api-context";
import { wsProxyApi } from "../ws-net";
import session from "../session";

const sessionUid = window.location.hash
  ? window.location.hash.slice(1)
  : undefined;

const WebSocketConnectionProver = ({ children }) => {
  // Initialize state to store the WebSocket instance
  const [api, setApi] = useState(null);
  const [adminApi, setAdminApi] = useState(null);

  // Use a ref to keep track of the latest ws value to avoid stale closures
  const apiRef = useRef(api);
  const adminApiRef = useRef(adminApi);

  function sessionDead(error) {
    console.log({ DEAD_SESSION: error });
    setTimeout(() => {
      // alert(error || "you have been logged out");
      window.location = "/";
    }, 2000);
  }

  // Function to initialize WebSocket connection
  const initializeAPI = async () => {
    const webSocket = await wsProxyApi("proxy");
    const adminWebSocket = await wsProxyApi("admin");

    webSocket.on_ready(() => {
      session.init(webSocket, sessionDead, sessionUid);
    });

    adminWebSocket.on_ready(() => {
      session.init(adminWebSocket, sessionDead, sessionUid);
    });

    // Update the ws state and ref
    setAdminApi(adminWebSocket);
    setApi(webSocket);
    apiRef.current = webSocket;
    adminApiRef.current = adminWebSocket;
  };

  // Effect hook to clean up the WebSocket when the component unmounts
  useEffect(() => {
    const initAsync = async () => {
      try {
        await initializeAPI();
      } catch (error) {
        console.error("Failed to initialize WebSocket:", error);
      }
    };

    initAsync();

    return () => {
      if (apiRef.current && apiRef.current.close) {
        apiRef.current.close();
      }
      if (adminApiRef.current && adminApiRef.current.close) {
        adminApiRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Provide the WebSocket instance to the context
  return (
    <WebSocketApiContext.Provider value={{ api, adminApi, initializeAPI }}>
      {children}
    </WebSocketApiContext.Provider>
  );
};

export default WebSocketConnectionProver;
