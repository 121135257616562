/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import Styles from "./styles/overlay.module.scss";

const Overlay = ({ open = false, destroyOnClose = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const timeOut = 100;

  useEffect(() => {
    let timeoutId;
    if (open) {
      document.body.classList.add("modal");
      setIsOpen(true);
      timeoutId = setTimeout(() => {
        setIsAnimate(true);
      }, timeOut);
    } else {
      document.body.classList.remove("modal");
      timeoutId = setTimeout(() => {
        setIsAnimate(false);
      }, timeOut);
    }
    return () => clearTimeout(timeoutId);
  }, [open]);

  useEffect(() => {
    let timeoutId;
    if (!isAnimate && destroyOnClose) {
      timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, timeOut);
    }
    return () => clearTimeout(timeoutId);
  }, [isAnimate, destroyOnClose]);

  return (
    <>
      <div className={`${Styles.overlay} ${isAnimate ? Styles.active : ""}`} />
    </>
  );
};

export default Overlay;
