import { Tabs } from "antd";

import Users from "./Users";
import Settings from "./Settings";
import ActiveUsers from "./ActiveUsers";

const Administration = () => {
  const onChange = (key) => {
    // console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: "1",
          label: "Settings",
          children: <Settings />,
        },
        {
          key: "2",
          label: "Users",
          children: <Users />,
        },
        {
          key: "3",
          label: "Active Users",
          children: <ActiveUsers />,
        },
      ]}
      centered
      onChange={onChange}
    />
  );
};

export default Administration;
