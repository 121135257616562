export const isNotRunningInStandaloneMode = () => {
  return !isRunningInStandaloneMode();
}

export const isRunningInStandaloneMode = () => {
  return !!getAppIdForStandaloneRun();
}

export const getAppIdForStandaloneRun = () => {
  return process.env.REACT_APP_LOCAL_ORG_ID;
}

export const getApiHostForStandaloneRun = () => {
  return process.env.REACT_APP_BACKEND_API_HOST;
}