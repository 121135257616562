import { useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { Spin, message } from "antd";

import { setupLLMApiSession } from "api/proxy/llm-api";
import { useSocketContext } from "api/core/context/websocket-api-context";
import {
  checkProxyStatus,
  setupAuthAPISession,
  userAuth,
} from "api/proxy/auth-api";
import { checkAdminStatus, setupAdminAPISession } from "api/proxy/admin-api";
import { LS } from "utils/utils";

import Layout from "components/layout/layout";
import AppDetails from "pages/AppsDetails";
import AppContext from "context/appContext";
import LoginPage from "pages/Login";
import Home from "pages/Home";
import Administration from "pages/Administration";

import "./App.css";
import LoginSuccessLanding from "pages/LoginSuccessLanding";

function App() {
  const { api, adminApi, initializeAPI } = useSocketContext();
  const [meData, setMeData] = useState(null);
  const [appList, setAppList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const codeParam = urlParams.get("code");

  useEffect(() => {
    if (api || adminApi) {
      setupAuthAPISession(api, setMeData);
      setupAdminAPISession(adminApi, setAppList);
      setupLLMApiSession(api);
    }
  }, [api, adminApi]);

  useEffect(() => {
    const session = LS.get("session");
    if (session) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      setMeData({});
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(() => {
        const session = LS.get("session");
        if (session) {
          userAuth(
            {
              ssn: session,
            },
            (msg, error) => {
              if (msg) {
                document.cookie = `morfius-session=${msg.sid}`;
                if (msg.user !== meData?.user) {
                  setMeData(msg);
                  LS.set("user", JSON.stringify(msg));
                }
              } else if (error) {
                message.open({
                  type: "error",
                  content: error,
                });
                setIsAuthenticated(false);
                clearInterval(interval);
                LS.delete("session");
                LS.delete("user");
              }
            }
          );
        } else {
          setIsAuthenticated(false);
          clearInterval(interval);
          LS.delete("session");
          LS.delete("user");
        }
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isAuthenticated, meData]);

  return (
    <AppContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, meData, setMeData }}
    >
      <HashRouter>
        {isAuthenticated === null || meData === null ? (
          <div className="loader">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!isAuthenticated ? (
              <Routes>
                {codeParam && (
                  <Route
                    path="/"
                    element={<LoginSuccessLanding codeParam={codeParam} />}
                  />
                )}
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<Navigate to={`/login`} replace />} />
              </Routes>
            ) : (
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/app/:id" element={<AppDetails />} />
                  {meData?.org_admin && (
                    <Route
                      path="/administration"
                      element={<Administration />}
                    />
                  )}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Layout>
            )}
          </>
        )}
      </HashRouter>
    </AppContext.Provider>
  );
}

export default App;
