import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Empty, Spin } from "antd";

import { useAppContext } from "context/appContext";
import { getApp } from "api/proxy/admin-api";

import Styles from "./styles/appDetails.module.scss";

const AppDetails = () => {
  const { meData } = useAppContext();
  const { id } = useParams();
  const [isValidApp, setIsValidApp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isLocalApp = Boolean(process.env.REACT_APP_IS_LOCAL);
  const localAppLink = process.env.REACT_APP_APP_URL;

  useEffect(() => {
    if (meData && id) {
      getApp({ admin: meData.org_admin, user: meData.user, uid: id }, (msg) => {
        if (msg) {
          setIsValidApp(true);
        } else {
          setIsValidApp(false);
        }
      });
    } else {
      setIsValidApp(false);
    }
    setIsLoading(false);
  }, [meData, id]);

  return (
    <div className={Styles["app-details-wrapper"]}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {isValidApp ? (
            <iframe
              src={`${
                isLocalApp ? localAppLink : window.location.origin
              }/app/${id}`}
              title={id}
              id="app"
            />
          ) : (
            <Empty description="Couldn't locate the application." />
          )}
        </>
      )}
    </div>
  );
};

export default AppDetails;
