import { Card } from "antd";

import Styles from "./styles/contentCard.module.scss";

const ContentCard = ({ children, transparent, ...props }) => {
  return (
    <Card
      className={`${Styles.card} ${transparent ? Styles.transparent : ""}`}
      {...props}
    >
      {children}
    </Card>
  );
};

export default ContentCard;
