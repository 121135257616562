import { LS } from "./utils";

// Function for searching using debounce method
export const debounceMethod = (func) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), 500);
  };
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const createSolutionData = (
  formValues,
  stepNumber,
  solutionId,
  formData
) => {
  const finalData = {
    assetId: Number(solutionId),
    attributes: [],
    status: formData.status,
    wizardPhase: 2,
  };

  if (stepNumber === 1) {
    if (formValues.persona.length) {
      finalData.attributes.push({ key: 205, value: formValues.persona });
    }

    if (formValues.kpis.length) {
      finalData.attributes.push({ key: 231, value: formValues.kpis });
    }

    if (formValues.industry.length) {
      finalData.attributes.push({ key: 214, value: formValues.industry });
    }

    if (formValues.cloud_infrastructure.length) {
      finalData.attributes.push({
        key: 203,
        value: formValues.cloud_infrastructure,
      });
    }

    if (formValues.problem) {
      finalData.attributes.push({
        key: 226,
        value: formValues.problem,
      });
    }

    if (formValues.solution_description) {
      finalData.attributes.push({
        key: 227,
        value: formValues.solution_description,
      });
    }

    if (formValues.demo_url) {
      finalData.attributes.push({
        key: 223,
        value: formValues.demo_url,
      });
    }

    if (formValues.code_repository) {
      finalData.attributes.push({
        key: 232,
        value: formValues.code_repository,
      });
    }
  } else if (stepNumber === 2) {
    if (formValues.architecture && !formValues.architecture_link) {
      finalData.attributes.push({
        key: 208,
        value: formData.architecture,
      });
    } else if (!formValues.architecture && formValues.architecture_link) {
      finalData.attributes.push({
        key: 208,
        value: formValues.architecture_link,
      });
    } else {
      finalData.attributes.push({
        key: 208,
        value: "",
      });
    }

    finalData.wizardPhase = 3;
  }
  return finalData;
};

export const generatePassword = () => {
  return Math.round(Math.random() & 0xffffffff).toString(36);
};


export const handleLaunchClick = (uid, appsContext) => {
  const ssn = LS.get("session");
  const direct = appsContext[uid];
  if (direct) {
    const { host, port } = direct;
    window.open(`http://${host}:${port}/app/${uid}#${ssn}`, uid);
  } else {
    window.open(`/app/${uid}`, uid);
  }
};