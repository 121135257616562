import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import WebSocketConnectionProver from "api/core/context/websocket-connection-provider";

import "./styles/_variables.scss";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#0991f2",
        fontFamily: '"Roboto Regular", sans-serif',
        colorBorder: "#D7D7D7",
        colorTextPlaceholder: "#8C8C8C",
      },
    }}
  >
    <WebSocketConnectionProver>
      <App />
    </WebSocketConnectionProver>
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
